import axios from 'axios'

import store from '@/store'

import router from '@/router'
 
const myAxios = axios.create({
  baseURL: 'https://dev.ask-express.com/api/',
  // timeout: 30000, // 超时
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
 
/**
 * 请求拦截器，用于添加统一的请求头
 */
myAxios.interceptors.request.use( config => {
  const token = store.state.api_token
  // 添加token
  if (token) { // 添加 token
    config.headers['ApiToken'] = token
  }
  return config
}, err => {
  // showErrToast(err)
  return Promise.reject(err)
})
 
/**
 * 响应拦截器，对响应状态码做统一的处理，报错给出报错提示
 */
myAxios.interceptors.response.use( res => {
  // console.log(res)
  if (res.data.code === 401) {
    store.commit('setToken', '')
    router.push('/login')
    return Promise.reject(res.data.msg)
  } else if (res.status === 200 && res.data.code === 400) {
    return Promise.reject(res.data.msg)
  }
  return res
}, err => {
  return Promise.reject(err)
})
 
 
export const $http = myAxios
 
/**
 * 针对vue的封装：对axios的实例重新封装成一个plugin ,方便 Vue.use(xxxx)，可以让$http挂在到vue上
 * 通过 this.$http 访问
 */
export default {
  install (Vue) {
    Object.defineProperty(Vue.prototype, '$http', { value: myAxios })
  }
}