<template>
  <div class="box">
    <div>
      <section
        class="aos-init aos-animate"
        data-aos="fade-up"
        id="example-carousel"
      >
        <div
          id="carouselExampleIndicators"
          class="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <div class="carousel-inner shadow-sm">
            <div class="carousel-item active" style="">
              <img
                class="d-block w-100"
                src="@/assets/8.jpeg"
                alt="First slide"
                style="height: 35rem"
              />
              <div
                class="carousel-text carousel-caption d-md-block"
                style="left: 10%; right: 10%; margin-top: -5%"
              >
                <h2 class="title h1">SERVICE</h2>
                <h2 class="title ht">小额混装物流服务</h2>
                <!-- <h2 class="title">
                  {{}}
                </h2> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- 小口混載物流サービス -->
    <div v-for="(item, index) in list" :key="index">
      <div class="container color pt-5 pl-0 pr-0">
        <p class="h3">{{ item.title }}</p>
      </div>
      <div class="container line"></div>
      <div class="container">
        <p class="h5 mt-5 mb-5">
          {{ item.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalpaScls',

  data() {
    return {
      list: [
        {
          title: '具体物流服务',
          content:
            '央视网消息(新闻联播) ：习近平总书记指出，发展数字经济意义重大，是把握新一轮科技革命和产业变革新机遇的战略选择。2022年,在经济下行压力加大的情况下,数字经济作为国民经济的“加速器”作用凸显,成为经济恢复向好的关键力量,为实体经济发展添能蓄力。',
        },
        {
          title: '具体物流服务',
          content:
            '今年以来,以习近平同志为核心的党中央,准确把握中国经济发展的阶段性特征，深刻洞察数字经济发展趋势和规律，作出一系列战略部署、出台一系列重大政策。《“十四五”数字经济发展规划》围绕数字基础设施、产业数字化转型等方面提出了“十四五”时期重点任务和重点工程；不久前出台的"数据二十条"意见,为数据基础制度体系搭建“四梁八柱"。',
        },
        {
          title: '具体物流服务',
          content:
            '目前，“东数西算“工程起步区新开工数据中心项目超过60个，项目总投资超过4000亿元。今年，我国5G基站数量超过222万个，建成了全球最大的移动宽带和光纤网络;截至目前,“5G+工业互联网“在建项目超过4000个, 5G应用已经覆盖国民经济40个大类。而移动物联网终端用户达到17.77亿户,超过了移动电话用户数,也成为全球主要经济体中首个实现“物超人”的国家。',
        },
        {
          title: '具体物流服务',
          content:
            '数字基础设施提速,让数字化转型成为制造业2022年的“关键词”。在广东佛山,到今年10月底,已经有3000多家规模以上工业企业实施了数字化转型。在山东,利用100多个工业互联网平台，链接了50万家大中小企业，打造出覆盖全链条的数字产业生态。在湖北,正在建设的5G全连接工厂就有108家。',
        },
      ],
    }
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="scss" scoped>
.box {
  margin-left: 5rem;
  margin-right: .625rem;
  #example-carousel {
    display: none;
  }
  .color {
    color: #000;
  }
  .line {
    border-bottom: 3px solid #132675;
    margin-bottom: 3rem;
  }
}

@media (max-width: 640px) {
  .box {
    margin: 0;
    #example-carousel {
      display: block;
      .carousel-item {
        img {
          height: 14.25rem !important;
          object-fit: cover;
        }

        .carousel-caption {
          // bottom: 10% !important;

          .h1 {
            font-size: 2.5rem;
            font-weight: bold;
          }

          .ht {
            font-size: 1.75rem;
          }
        }
      }
    }
    .color {
      p {
        font-size: 2rem;
        font-weight: bold;
        padding: 0 1.875rem;
      }
    }

    .line {
      width: 92%;
    }

    .container {
      .h5 {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
