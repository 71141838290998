<template>
  <div class="root">
    <!-- 头部导航栏 -->
    <nav
      class="navbar navbar-expand-lg navbar-light bg-white"
      style="justify-content: content;border-bottom: 2px solid #707070;"
    >
      <div class="container m-0" style="max-width: 1920px">
        <!-- Brand -->
        <a class="navbar-brand mr-3" href="/">
          <img
            alt="Image placeholder"
            src="@/assets/logo.jpg"
            id="navbar-logo"
          />
        </a>
        <!-- Toggler -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Collapse -->
        <div
          style="flex-grow: 0"
          class="collapse navbar-collapse justify-content-content"
          id="navbarCollapse"
        >
          <ul class="navbar-nav">
            <li class="nav-item" v-for="(item, index) in navList" :key="index">
              <router-link :to="item.path">
                <a
                  :class="index == isShow ? 'active' : ''"
                  class="nav-active nav-link h5 mr-4 ml-4"
                  @click="toContainer(index)"
                  href="javascript:;"
                  >{{ item.name }}</a
                >
              </router-link>
            </li>
            <li class="nav-btn" v-if="!api_token">
              <button
                class="link-btn h6 mb-0 mr-4 ml-4 pl-4 pr-4 pt-1 pb-1"
                @click="$router.push('/login')"
              >
                登录
              </button>
            </li>
            <li class="nav-btn" v-else>
              <div
                class="link-btn h6 mb-0 mr-4 ml-4 pl-4 pr-4 pt-1 pb-1"
                @click="$router.push('personalCenter')"
              >
                个人中心
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- 移动端导航 -->
    <nav class="mobile-navbar">
      <!-- 头部 -->
      <div class="mobile-nav">
        <div class="left" @click="$router.push('/')">
          <img src="@/assets/logo.jpg" alt="" />
        </div>
        
        <div class="right">
          <!-- 登录 -->
          <div class="head" v-if="api_token" @click="$router.push('personalCenter')">
            <img src="@/assets/head_portrait.jpeg" alt="" />
          </div>
          <!-- 未登录 -->
          <div class="head" v-else @click="$router.push('/login')">
            <img @click="$router.push('/login')" src="@/assets/not-login.png" alt="" />
          </div>

          <div class="head" @click="show()">
            <img style="background: #000" :src="imgSrc" alt="" />
          </div>
        </div>
      </div>
      <!-- 导航列表 -->
      <div class="nav-list" v-if="navListShow">
        <ul>
          <li v-for="(item, index) in navList" :key="index">
            <div class="text" @click="childrenListShow(item)">
              <span style="font-size: 1.5rem">{{ item.nameEN }}</span>
              <span style="font-weight: bold">{{ item.name }}</span>
            </div>
            <ul v-if="childrenShow" class="children">
              <li
                v-for="(it, idx) in item.children"
                :key="idx"
                @click="toChildren(it)"
              >
                <span>{{ it.name }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>

    <!-- 二级路由 -->
    <router-view />

    <!-- 底部 -->
    <footer id="footer" class="bg bg-black">
      <div class="boundary"></div>
      <div class="container pb-5">
        <h5 class="mb-4 mt-5 text-white" style="font-weight: bold">
          catalpa国际物流株式会社
        </h5>
        <div class="row">
          <div class="row-text col-12 col-md-4 font-lighter">
            <div class="d-block mt-3 text-white">〒162-0041</div>
            <div class="d-block text-white">
              東京都新宿区早稲田鶴巻町535カーサ協和1F 101
            </div>
            <div class="d-block mt-3 text-white">TEL: 03-6807-2256</div>
            <div class="d-block text-white">
              Email: expressASKgroup@gmail.com
            </div>
          </div>
          <div class="col-center-block m-auto col-16 col-md-8">
            <div class="footer-nav">
              <div
                v-for="(item, index) in footerList1"
                :key="index"
                class="d-inline-block mt-3 mr-5"
              >
                <router-link :to="{ path: `${item.path}`, query: { index } }">
                  <a class="text-white" href="javascript:;">{{ item.name }}</a>
                </router-link>
              </div>
            </div>
            <div class="footer-nav">
              <div
                v-for="(item, index) in footerList2"
                :key="index"
                class="d-inline-block mt-3 mr-5"
              >
                <router-link :to="{ path: `${item.path}`, query: { index } }">
                  <a class="text-white" href="javascript:;">{{ item.name }}</a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">©Catalpa Co.,Ltd. All rights Reserved.</div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CatalpaIndex',

  data() {
    return {
      navList: [
        {
          name: '首页',
          nameEN: 'HOME',
          path: '/',
        },
        {
          name: '会社概要',
          nameEN: 'COMPANY',
          path: '/profile',
        },
        {
          name: '事业内容',
          nameEN: 'SERVICE',
          path: '/business',
          children: [
            {
              name: '小额混装物流服务',
              path: '/business',
            },
            {
              name: '国际综合运输服务',
              path: '/business/icss',
            },
            {
              name: '报关服务',
              path: '/business/cs',
            },
            {
              name: '国际物流服务',
              path: '/business/ils',
            },
          ],
        },
        {
          name: '物流查询',
          nameEN: 'LOGISTICS QUERY',
          path: '/information',
        },
        {
          name: '通知',
          nameEN: 'NEWS',
          path: '/notice',
        },
        {
          name: '咨询',
          nameEN: 'CONTACT',
          path: '/contact',
        },
      ],
      footerList1: [
        {
          name: '会社概要',
          path: '/profile',
        },
        {
          name: '物流查询',
          path: '/information',
        },
        {
          name: '通知',
          path: '/notice',
        },
        {
          name: '咨询',
          path: '/contact',
        },
      ],
      footerList2: [
        {
          name: '小额混装物流服务',
          path: '/business',
        },
        {
          name: '国际综合运输服务',
          path: '/business/icss',
        },
        {
          name: '报关服务',
          path: '/business/cs',
        },
        {
          name: '国际物流服务',
          path: '/business/ils',
        },
      ],

      // token: window.localStorage.getItem('token'),

      imgSrc: require('@/assets/open.png'),
      open: require('@/assets/open.png'),
      close: require('@/assets/close.png'),

      // 导航是否显示
      navListShow: false,
      // 子导航是否显示
      childrenShow: false,
    }
  },

  computed: {
    ...mapState(['api_token']),
    isShow: {
      get() {
        return this.navList.findIndex((item) => {
          return item.path == this.$route.path
        })
      },
      set(val) {
        console.log(val)
      },
    },
  },

  updated() {
    // this.token = window.localStorage.getItem('token')
  },

  methods: {
    toChildren(it) {
      this.$router.push(it.path)
      this.navListShow = false
      this.imgSrc = this.open
    },
    // 是否显示子导航
    childrenListShow(item) {
      if (item.children) {
        this.childrenShow = !this.childrenShow
      } else {
        this.$router.push(item.path)
        this.navListShow = false
        this.imgSrc = this.open
      }
    },
    // 是否显示导航列表
    show() {
      this.navListShow = !this.navListShow
      if (this.navListShow) {
        this.imgSrc = this.close
      } else {
        this.imgSrc = this.open
      }
      this.childrenShow = false
    },
    // 头部导航路由跳转
    toContainer(index) {
      // console.log("index",index)
      this.isShow = index
    },
  },
}
</script>

<style lang="scss" scoped>
  .root {
    .mobile-navbar {
      display: none;
    }
    #navbar-logo {
      margin-left: 5rem;
      width: 4rem;
      height: 4rem;
    }
    .nav-active {
      margin-bottom: 0;
      padding: 20px 0;
    }
    .active {
      border-bottom: 3px solid #132675;
    }
    .bg {
      background: #132675;
      // display: inline;
    }
    .col-center-block {
      text-align: right;
    }

    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .link-btn {
      border: 0;
      color: #fff;
      background: #132675;
      font-size: 1.125rem;
      
      cursor: pointer;
    }

    #footer {
      color: #fff;
      padding-top: 5rem;

      .boundary {
        height: 0.625rem;
        background: #efce71;
      }

      .copyright {
        font-size: 0.75rem;
        text-align: center;
        padding: 0.5rem;
      }
    }

    .font-lighter {
      font-weight: inherit;
    }
  }
@media (min-width: 640px) {

}

@media (max-width: 640px) {
  .root {
    .navbar {
      display: none;
    }

    .mobile-navbar {
      display: block;
      box-shadow: 0 0 10px 0 #ccc;
      z-index: 999;

      .mobile-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          width: 12.75rem;
          height: 12.75rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .right {
          display: flex;
          justify-content: space-evenly;
          margin-right: 2rem;

          .head {
            width: 7.5rem;
            height: 7.5rem;
            border-radius: 50%;
            margin: 0 0.5rem;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .nav-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 24.25rem;
      background: #fff;
      z-index: 999;
      ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
          width: 100%;
          list-style: none;
          font-size: 2rem;
          font-weight: bold;
          color: #fff;
          border-bottom: 0.25rem solid #fff;
          border-right: 0.25rem solid #fff;

          .text {
            background: #0e145e;
            padding: 1rem 0 1rem 2rem;
            span {
              display: block;
            }
          }

          .children {
            padding-left: 0;
            margin-bottom: 0;
            // background: #dfdede;
            font-size: 1.5rem;

            li {
              width: 100%;
              list-style: none;
              color: #000;
              font-weight: normal;
              background: #dfdede;
              border: 0;
              padding: 1.25rem 0 1.25rem 1.5rem;
              margin: 0.25rem 0;
            }
          }
        }
      }
    }

    #footer {
      text-align: center;

      .container {
        h5 {
          font-size: 2rem;
        }

        .row-text {
          font-size: 1.625rem;
        }
        .col-center-block {
          // text-align: center;
          display: flex;
          flex-direction: column;
          font-size: 1.75rem;
          padding: 5rem 0;

          .footer-nav {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-flow: wrap;
          }
        }
      }
    }
  }
}
</style>
