// import Vue from 'vue'

//弹出框禁止滑动

const noScroll = function () {

  var mo = function (e) { e.preventDefault() }

  document.body.style.overflow = 'hidden'

  document.addEventListener('touchmove', mo, false)// 禁止页面滑动

}

//弹出框可以滑动

const canScroll = function () {

  var mo = function (e) {

    e.preventDefault()

  }

  document.body.style.overflowY = 'scroll'// 出现滚动条

  document.removeEventListener('touchmove', mo, false)

}

export default {noScroll,canScroll}