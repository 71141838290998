import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import index from '@/view/index.vue'
import home from '@/view/home/home.vue'
import scls from '@/view/business/scls/scls.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)      //Vue中使用router插件

const routes = [        //路由配置，配置路由路径与组件的对应关系
  {
    path: '/',
    component: index,
    children: [
      {
        path: '',
        name: 'home',
        component: home
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/view/profile/profile.vue')
      },
      {
        path: 'business',
        name: 'business',
        component: () => import('@/view/business/business.vue'),
        children: [
          {
            path: '',
            name: 'scls',
            component: scls
          },
          {
            path: 'icss',
            name: 'icss',
            component: () => import('@/view/business/icss/icss.vue')
          },
          {
            path: 'cs',
            name: 'cs',
            component: () => import('@/view/business/cs/cs.vue')
          },
          {
            path: 'ils',
            name: 'ils',
            component: () => import('@/view/business/ils/ils.vue')
          },
        ]
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('@/view/information/information.vue')
      },
      {
        path: 'notice',
        name: 'notice',
        component: () => import('@/view/notice/notice.vue')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/view/contact/contact.vue')
      },
      {
        path: 'personalCenter',
        name: 'personalCenter',
        component: () => import('@/view/personalCenter/personalCenter.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        component: () => import('@/view/personalCenter/orders.vue')
      },
      {
        path: 'orderManagement',
        name: 'orderManagement',
        component: () => import('@/view/personalCenter/orderManagement.vue')
      },
      {
        path: 'conTran',
        name: 'conTran',
        component: () => import('@/view/personalCenter/conTran.vue')
      },
      {
        path: 'contactUs',
        name: 'contactUs',
        component: () => import('@/view/personalCenter/contactUs.vue')
      },
      {
        path: 'cargoInfo',
        name: 'cargoInfo',
        component: () => import('@/view/personalCenter/cargoInfo.vue')
      },
      {
        path: 'orderDetails',
        name: 'orderDetails',
        component: () => import('@/view/personalCenter/orderDetails.vue')
      },
      {
        path: 'sci',
        name: 'sci',
        component: () => import('@/view/personalCenter/sci.vue')
      },
      {
        path: 'UploadDetails',
        name: 'UploadDetails',
        component: () => import('@/view/personalCenter/uploadDetails.vue')
      },
      {
        path: '/inventory',
        name: 'inventory',
        component: () => import('@/view/personalCenter/article/inventory.vue')
      },
      {
        path: '/package',
        name: 'package',
        component: () => import('@/view/personalCenter/article/package.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/view/register/register.vue')
  },
  {
    path: '/amend',
    name: 'amend',
    component: () => import('@/view/password/amend.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/view/password/reset.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/view/404.vue'),
    hidden: true
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({    //新建路由实例
  routes,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})

// 白名单
const whiteList = [
  '/',
  '/login',
  '/register',
  '/reset',
  '/profile',
  '/business',
  '/business/icss',
  '/business/cs',
  '/business/ils',
  '/information',
  '/notice',
  '/contact',
  '/404',
  '/package',
  '/inventory',

]

const whiteList2 = [
  "/amend",
  "/personalCenter",
  "/orders",
  "/orderManagement",
  "/conTran",
  "/contactUs",
  "/cargoInfo",
  "/orderDetails",
  "/sci",
  "/UploadDetails",
]

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // console.log(to,from,next)
  //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
  //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
  //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
  const token = store.state.api_token
  if (token) {
    if (to.path == 'login') {
      next('/personalCenter')
    } else {
      next()
    }
  } else {
    // console.log(to.path)
    if (whiteList.includes(to.path)) {
      next()
    } else {
      // console.log(routes)
      // next('/')
      if (whiteList2.includes(to.path)) {
        next('/login')
      } else {
        next('/')
      }
    }
  }
})

export default router         //导出路由实例，在main.js中导入使用