<template>
  <div class="box">
    <!-- 展示图片 -->
    <div>
      <section
        class="aos-init aos-animate"
        data-aos="fade-up"
        id="example-carousel"
      >
        <div
          id="carouselExampleIndicators"
          class="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <div class="carousel-inner shadow-sm">
            <div class="carousel-item active" style="">
              <img
                class="d-block w-100"
                src="@/assets/ (6).jpeg"
                alt="First slide"
              />
              <div
                class="carousel-caption d-md-block"
                style="top: 50%; left: 10%; right: 10%; margin-top: -7%"
              >
                <h2 class="title">连接日本和中国的网络平台，</h2>
                <h2 class="title">一站式，提供安全 · 最佳的国际物流服务。</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 物流查询 -->
      <div class="search">
        <div
          class="search-item"
          style="background: #fff; height: 12rem; width: 30rem"
        >
          <div class="search-ipt">
            <input
              v-model="order_no"
              type="text"
              placeholder="您可以输入单号进行查询"
            />
            <span
              class="button"
              @click="
                $router.push({ path: `/information?order_no=${order_no}` })
              "
              ><img src="@/assets/search.png" alt=""
            /></span>
          </div>
          <div class="search-btn">
            <button
              style="border: 0"
              @click="
                $router.push({ path: `/information?order_no=${order_no}` })
              "
            >
              立即查询
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- サービスの概要 -->
    <div class="profile">
      <div class="container line p-5"></div>
      <div class="container color">
        <p class="h1 mb-4">服务概述</p>
        <p class="h3">Service introduction</p>
      </div>
      <div class="row" style="margin-right: 0">
        <div class="col-lg-3 p-0" @click="$router.push('/business')">
          <div
            style="border-radius: 0"
            class="card card-pin bg-dark overlay overlay-blue text-white shadow-sm border-0 mt-sm-4 mt-lg-0"
          >
            <img class="card-img" src="@/assets/ (7).jpeg" alt="Card image" />
            <div class="card-img-overlay d-flex align-items-center text-center">
              <div class="card-body hover-border">
                <h3 class="card-title title">小额混装物流服务</h3>
              </div>
            </div>
            <div class="profile-line top"></div>
            <div class="profile-line right"></div>
            <div class="profile-line bottom"></div>
            <div class="profile-line left"></div>
          </div>
        </div>
        <div class="col-lg-3 p-0" @click="$router.push('/business/cs')">
          <div
            style="border-radius: 0"
            class="card card-pin bg-dark overlay overlay-blue text-white shadow-sm border-0 mt-sm-4 mt-lg-0"
          >
            <img class="card-img" src="@/assets/ (11).jpeg" alt="Card image" />
            <div class="card-img-overlay d-flex align-items-center text-center">
              <div class="card-body hover-border">
                <h3 class="card-title title">报关服务</h3>
              </div>
            </div>
            <div class="profile-line top"></div>
            <div class="profile-line right"></div>
            <div class="profile-line bottom"></div>
            <div class="profile-line left"></div>
          </div>
        </div>
        <div class="col-lg-3 p-0" @click="$router.push('/business/icss')">
          <div
            style="border-radius: 0"
            class="card card-pin bg-dark overlay overlay-blue text-white shadow-sm border-0 mt-sm-4 mt-lg-0"
          >
            <img class="card-img" src="@/assets/ (1).jpg" alt="Card image" />
            <div class="card-img-overlay d-flex align-items-center text-center">
              <div class="card-body hover-border">
                <h3 class="card-title title">国际综合运输服务</h3>
              </div>
            </div>
            <div class="profile-line top"></div>
            <div class="profile-line right"></div>
            <div class="profile-line bottom"></div>
            <div class="profile-line left"></div>
          </div>
        </div>
        <div class="col-lg-3 p-0" @click="$router.push('/business/ils')">
          <div
            style="border-radius: 0"
            class="card card-pin bg-dark overlay overlay-blue text-white shadow-sm border-0 mt-sm-4 mt-lg-0"
          >
            <img class="card-img" src="@/assets/ (13).jpeg" alt="Card image" />
            <div class="card-img-overlay d-flex align-items-center text-center">
              <div class="card-body hover-border">
                <h3 class="card-title title">国际物流服务</h3>
              </div>
            </div>
            <div class="profile-line top"></div>
            <div class="profile-line right"></div>
            <div class="profile-line bottom"></div>
            <div class="profile-line left"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 日中間の物流 -->
    <div class="logistics">
      <div class="container line p-5"></div>
      <div class="container color">
        <p class="h1 mb-4" style="font-weight: 600">
          如果是日中之间的物流的话,请务必交给Catalpa。
        </p>
        <!-- <p class="h3">Service introduction</p> -->
      </div>
      <div class="container">
        <p class="text">
          我们始终站在客户的角度,将复杂的物流简单化,作为海货系国际物流运营商,从整体的角度提出最佳的物流方案,根据客户的需求,提供适当的服务。
        </p>
        <p class="text">
          在中国的主要港口与众多的物流公司合作,运用遍布中国国内的网络，安排到目的地的一贯运输。
        </p>
        <p class="text">
          作为运输的协调员,为了满足顾客的所有要求,我们提供了陆、海、空、所有的物流服务。我们承诺,熟练的工作人员将切实地完成各项业务,以快速、可靠、安全、低成本将您的货物送到目的地。
        </p>
        <p class="text">初次贸易的客户也可以安心操作的网站,请放心交给我。</p>
      </div>
    </div>

    <!-- 会社概要 -->
    <div class="association">
      <div class="container line p-5"></div>

      <div class="mobile">
        <img src="@/assets/ (5).jpeg" alt="" />
        <div class="association-content">
          <h1 class="secondfont mb-3 font-weight-bold">会社概要</h1>
          <div
            class="mb-3 h6 webkit association-text"
            style="
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 10;
            "
          >
            物流方面：逐渐完善物流体系 包含当地派送 和快递业务，国际船舶
            航空运输。<br />通关业务，以物流发展为基础，发展网络配送业;为了不让优秀的设计师和作品埋没,我们成立了销售设计师作品的网络商店(平台),希望围绕这个想法发展公司和物流.商品个性化定制和特色设计品这个行业的现状刚刚开始发展,未来潜力巨大。公司以商品物流为基础、完善网上购物体验。在所有领域展开物流+网络购物(原创设计品),继续挑战实现这一目标。
          </div>
          <div style="text-align: center">
            <router-link to="/profile">
              <a
                href="javascript:;"
                style="
                  font-size: 2rem;
                  background: #132675;
                  border-color: rgba(0, 0, 0, 0);
                "
                class="btn btn-dark m-3"
                >查看详情</a
              >
            </router-link>
          </div>
        </div>
      </div>

      <div class="content container mb-5" style="margin-top: 8rem">
        <div
          class="jumbotron-fluid mb-3 pt-0 pb-0 bg-lightblue position-relative"
          style="color: #707070"
        >
          <div class="ml-4 mr-4 h-100 tofront">
            <div
              class="row justify-content-between"
              style="background: #f3f3f3"
            >
              <div
                class="col-md-4 pt-6 pb-6 align-self-center m-3"
                style="display: block"
              >
                <h1 class="secondfont mb-3 font-weight-bold">会社概要</h1>
                <div
                  class="mb-3 h6 webkit"
                  style="
                    line-height: 1.5rem;
                    height: auto;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 11;
                  "
                >
                  物流方面：逐渐完善物流体系 包含当地派送 和快递业务，国际船舶
                  航空运输。<br />通关业务，以物流发展为基础，发展网络配送业;为了不让优秀的设计师和作品埋没,我们成立了销售设计师作品的网络商店(平台),希望围绕这个想法发展公司和物流.商品个性化定制和特色设计品这个行业的现状刚刚开始发展,未来潜力巨大。公司以商品物流为基础、完善网上购物体验。在所有领域展开物流+网络购物(原创设计品),继续挑战实现这一目标。
                </div>
                <div style="text-align: center">
                  <router-link to="/profile">
                    <a
                      href="javascript:;"
                      style="
                        background: #132675;
                        border-color: rgba(0, 0, 0, 0);
                      "
                      class="btn btn-dark m-3"
                      >查看详情</a
                    >
                  </router-link>
                </div>
              </div>
              <div class="col-md-7 d-none d-md-block pr-0 img"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- お知らせ -->
    <div class="news">
      <div class="container line p-5"></div>
      <div class="container color">
        <p class="h1 mb-4">最新通知</p>
        <p class="h3">NEWS</p>
      </div>
      <div class="row ml-5 mr-5">
        <div
          v-for="(item, index) in news"
          :key="index"
          class="news-card col-md-4 pl-5 pr-5 mt-3"
        >
          <div class="card" style="background: #f3f3f3">
            <div class="card-body" style="padding-bottom: 5rem; height: 25rem;">
              <h5 class="h4 lh-130 mb-3 mt-3" style="color: #132675">
                {{ index < 9 ? `0${index + 1}` : index + 1 }} {{ item.name }}
              </h5>
              <p class="text-muted" style="font-size: 0.8rem">
                {{ item.update_time }}
              </p>
              <p
                class="news-content mb-0"
                style="
                  color: #000;
                  display: -webkit-box;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 9;
                "
                v-html="item.content"
              ></p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="more p-3 mb-5 mr-5 pr-5 pt-0"
        style="
          text-align: right;
          cursor: pointer;
          height: 3rem;
          line-height: 3rem;
        "
      >
        <span
          style="display: inline-block; width: 2rem; height: 2rem"
          @click="toNews"
          ><img
            style="
              width: 100%;
              height: 100%;
              background: #132675;
              border-radius: 1rem;
            "
            src="@/assets/more.png"
            alt=""
        /></span>
        查看更多通知
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalpaHome',

  data() {
    return {
      order_no: '', // 订单号

      news: [],
    }
  },

  created() {
    this.getNews()
  },

  methods: {
    toNews() {
      this.$router.push('notice')
    },

    async getNews() {
      try {
        const { data: res } = await this.$http.post('cms.Content/list')
        // console.log(res)
        // this.news = res.data.list
        res.data.list.forEach(item => {
          if (item.category_id == 3) {
            this.news.push(item)
          }
        });
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  .mobile {
    display: none;
  }
  .title {
    text-shadow: 0 0 5px #000;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .line {
    border-bottom: 3px solid #132675;
  }
  .color {
    color: #132675;
    text-align: center;
    padding: 6rem;
  }

  .carousel-item {
    height: 38.375rem;

    img {
      height: 100%;
    }
  }

  .search {
    position: relative;

    .search-item {
      position: absolute;
      top: -12rem;
      left: 5rem;
      padding: 2rem 3rem;
      box-shadow: 0px 1.25px 0.3125rem 0px rgba(0, 0, 0, 0.5);
      z-index: 10;

      .search-ipt {
        display: flex;
        // width: 60%;
        // height: 4rem;
        background: #eee;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        input {
          flex: 9;
          // width: 90%;
          // height: 100%;
          border: 0; // 去除未选中状态边框
          outline: none; // 去除选中状态边框
          background-color: rgba(0, 0, 0, 0); // 透明背景
          padding-left: 1rem;
        }

        .button {
          display: flex;
          // flex: 2;
          width: 3rem;
          height: 3rem;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          color: #fff;
          background: #132675;
          cursor: pointer;

          img {
            width: 80%;
            height: 80%;
          }
        }
      }

      .search-btn {
        width: 100%;
        button {
          display: block;
          width: 100%;
          height: 2.5rem;
          line-height: 2.5rem;
          font-size: 1.3rem;
          color: #fff;
          background: #132675;
          text-align: center;
          margin-top: 3rem;
        }
      }
    }
  }

  .container {
    .h1 {
      font-weight: normal;
    }
    .h3 {
      font-weight: normal;
    }
    .text {
      line-height: 1.875rem;
      color: rgba(0, 0, 0, 1);
      padding: 0 5rem;
      margin-bottom: 0.7rem;
      font-size: 1.3rem;
    }
    .h6 {
      padding: 0;
      font-size: 1.1rem;
      font-weight: normal;
      letter-spacing: 0.125rem;
    }
    .h7 {
      padding: 0;
      font-size: 0.8rem;
      font-weight: normal;
    }
  }

  .card {
    width: 100%;
    flex-direction: row;
    border: 0;
    img {
      width: 100%;
      height: 18rem;
    }
  }

  .img {
    background-size: cover;
    background-image: url('@/assets/ (5).jpeg');
    background-position: 100%;
  }

  .webkit {
    word-wrap: break-word; // 自动换行
    word-break: break-all; // 英文强制自动换行
    overflow: hidden;
    text-overflow: ellipsis; // 超出部分显示省略号
  }

  // .card-pin {
  // &:hover {
  // .card-img-overlay {
  //   border: 5px solid #fff;
  //   transition: ease 0.4s;
  //   color: #fff;
  //   background-color: rgba(0, 0, 0, .2);

  //   .hover-border {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 100%;
  //     height: 100%;
  //     border: 4px solid #fff;
  //   }
  // }
  // }
  .card-pin {
    position: relative;
    // height: 200px;
    // width: 200px;
    // padding: 10px;
    margin: 6.25rem auto;
    background: #eee;
    overflow: hidden; /*设置overflow因此隐藏掉4个模拟边框，鼠标移入后再显示出来*/

    // .card-img-overlay {
    //   height: 100%;
    //   color: #fff;
    //   font-size: 30px;
    //   line-height: 200px;
    //   text-align: center;
    //   background: #ccc;
    // }

    .top,
    .bottom {
      height: 0.25rem;
      width: 50%;
      background: rgb(255, 255, 255);
    }

    .left,
    .right {
      height: 72%;
      width: 0.25rem;
      background: rgb(255, 255, 255);
    }

    .top {
      position: absolute;
      top: 2.5rem;
      left: -53.75rem;
      transition: all 1s ease; /*必须要有，不然数百移出来的时候就不会有动画返回效果*/
    }

    .left {
      position: absolute;
      bottom: -13.75rem;
      left: 3rem;
      transition: all 1s ease;
    }
    .right {
      position: absolute;
      top: -13.75rem;
      right: 3rem;
      transition: all 1s ease;
    }
    .bottom {
      position: absolute;
      bottom: 2.5rem;
      right: -53.75rem;
      transition: all 1s ease;
    }

    &:hover {
      .card-img-overlay {
        border: 0.3125rem solid #fff;
        transition: ease 0.4s;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.2);

        .hover-border {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border: 4px solid #fff;
          // border-top-width:1px;

          // border-right-width:5px;

          // border-bottom-width:10px;

          // border-left-width:15px;
        }
      }
      .top {
        left: 3rem;
        transition: all 0.7s ease;
      }
      .left {
        bottom: 2.5rem;
        transition: all 0.7s ease;
      }
      .right {
        top: 2.5rem;
        transition: all 0.7s ease;
      }
      .bottom {
        right: 3rem;
        transition: all 0.7s ease;
      }
    }
  }
  // }
}

@media (max-width: 640px) {
  .box {
    .line {
      // border-bottom: 3px solid #132675;
      width: 90%;
    }

    .search {
      display: none;
    }

    #example-carousel {
      .carousel-item {
        // height: 69.25rem !important;
        img {
          width: 100%;
          height: 69.25rem;
          object-fit: cover;
        }

        .title {
          font-size: 2.5rem;
        }
      }
    }

    .profile {
      .container {
        display: none;
      }

      .row {
        margin-top: 10rem;
        margin-right: 0;
        margin-left: 0;

        .col-lg-3 {
          .card {
            margin: 0;

            img {
              width: 100%;
              height: 21.25rem;
            }

            .profile-line {
              display: none;
            }

            .hover-border {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border: 4px solid #fff;
            }
          }
        }
      }
    }

    .logistics {
      .container {
        .text {
          font-size: 1.75rem;
          line-height: 4rem;
          padding: 0;
          text-indent: 2em;
          -webkit-text-indent: 2em;
          -moz-text-indent: 2em;
          -o-text-indent: 2em;
          -ms-text-indent: 2em;
        }
      }
    }

    .mobile {
      position: relative;
      display: block;
      margin-top: 6rem;

      img {
        opacity: 0.3;
        width: 100%;
        height: 55.5rem;
        object-fit: cover;
      }

      .association-content {
        position: absolute;
        top: 0;
        left: 0;

        .association-text {
          font-size: 2rem;
          line-height: 4rem;
          padding: 0 2.25rem;
          text-indent: 2em;
          -webkit-text-indent: 2em;
          -moz-text-indent: 2em;
          -o-text-indent: 2em;
          -ms-text-indent: 2em;
        }
        .secondfont {
          font-size: 5.25rem;
          text-align: center;
          color: #132675;
        }
      }
    }

    .association {
      .content {
        display: none;
      }
    }

    .news {
      .container {
        .h1 {
          font-size: 4.5rem;
          font-weight: bold;
        }
        .h3 {
          font-size: 3rem;
        }
      }

      .row {
        margin: 0 !important;
        .news-card {
          padding: 0 !important;
          margin-top: 2.5rem !important;
          .card-body {
            height: 20rem;
            padding: 3rem 5rem 3rem 3.5rem;
            .h4 {
              display: inline-block;
              font-size: 2.5rem;
              padding-right: 3rem;
            }
            .text-muted {
              display: inline-block;
            }
            .news-content {
              height: 8.5rem !important;
              -webkit-line-clamp: 4 !important;
            }
          }
        }
      }
    }
  }
}
</style>
