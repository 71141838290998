import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state(){
    return {
      api_token: JSON.parse(window.localStorage.getItem('token')) || '',
      userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')) || ''
    }
  },
  mutations: {
    // 设置token
    setToken (state, newToken) {
      // 在state中保存token
      state.api_token = newToken
      // 持久化
      window.localStorage.setItem('token', JSON.stringify(newToken))
    },

    setUser (state,value) {
      // console.log(value)
      state.userInfo = value
      window.sessionStorage.setItem('userInfo', JSON.stringify(value))
    }

  },
  actions: {
    
  }
})
export default store
