<template>
  <div id="app">
    <!-- 一级路由 -->
    <router-view />
  </div>
</template>

<script>

// import '@/libs/rem.js'

export default {
  name: 'App',
  created() {
    // if (this.$router.path !== '/') {
    //   this.$router.replace('/')
    // }
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
  },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap.scss';

body {
  font-family: Segoe UI !important;
}
body{font-family:Helvetica,Tahoma, Arial,"PingFang SC",STXihei,"Microsoft yahei","WenQuanYi Micro Hei",sans-serif !important;}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;
  letter-spacing: 0.25rem;
  // touch-action: none;

  

  a {
    text-decoration: none;
  }

  .el-input__inner {
    border-radius: 0 !important;
    border: 1px solid #000 !important;
  }

  .el-date-editor .el-range-separator {
    width: 8%;
    padding: 0;
    
  }

  .cursor {
    cursor: pointer;
  }
}
@media screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 640px) {
  html {
    font-size: 8px;
  }
}
</style>
