import Vue from 'vue'
import App from './App.vue'

import router from './router'

import scroll from './libs/scroll'

import 'bootstrap'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 导入store
import store from './store'

// 导入axios
import $http from '@/utils/request'
// axios.defaults.baseURL = 'https://dev.ask-express.com/api/'
Vue.use($http)

Vue.config.productionTip = false

Vue.prototype.scroll = scroll

Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  router,
  store,
  // ElementUI
}).$mount('#app')
